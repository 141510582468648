<template>
	<el-dialog class="z-dialog" :title="title" :visible.sync="isShow" width="550" :close-on-click-modal="false">
		<section class="video-box" v-if="data.type == 0">
	    <video controls="controls" :src="videoUrl" id="videoPlay" class="video" style="width:100%;max-height:400px;">您的浏览器不支持 video 视屏播放。请使用IE8+，谷歌，火狐，360等最新浏览器查看。</video>
		</section> 

		<section class="carousel-box" v-else-if="data.type == 1">
			<!-- swiper1 -->
			<swiper :options="swiperOptionTop" class="gallery-top" ref="swiperTop">
				<swiper-slide v-for="(item, index) in picList" :key="index">
					<p class="text-center margin-bottom__mini">{{item.imgName}}</p>
					<div class="img-box">
						<img :src="item.imgUrl" :alt="`image${index}`" class="carousel-img" :preview="index" :preview-text="item.imgName"/>
					</div>
				</swiper-slide>

				<div class="swiper-button-next" slot="button-next"></div>
				<div class="swiper-button-prev" slot="button-prev"></div>
			</swiper>
			<!-- swiper2 Thumbs -->
			<swiper :options="swiperOptionThumbs" class="gallery-thumbs" ref="swiperThumbs">
				<swiper-slide v-for="(item, index) in picList" :key="index">
					<img :src="item.imgUrl" :alt="`image${index}`" class="carousel-img" />
				</swiper-slide>
			</swiper>
		</section>
		
	</el-dialog>
</template>

<script>
	import "swiper/dist/css/swiper.css";

	import { swiper, swiperSlide } from "vue-awesome-swiper";
	export default {
		components: {
			swiper,
			swiperSlide
		},

		data() {
			return {
				currentCheckImg: '',
				innerVisible: false,
				imgZoom: 1,
        swiperOptionTop: {
          spaceBetween: 10,
          loop: true,
          loopedSlides: 5, //looped slides should be the same
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        swiperOptionThumbs: {
          spaceBetween: 10,
          slidesPerView: 1,
          touchRatio: 0.2,
          loop: true,
          loopedSlides: 5, //looped slides should be the same
          slideToClickedSlide: true,
        },
				picList: [],
				videoUrl: '',
				title: '',
				imgName: ''
			}
		},

		props: {
			show: {
				type: Boolean,
				default: false
			},

			data: {}
		},

		computed: {
			isShow: {
				get() {
					return this.show;
				},
				set(val) {
					this.$emit('update:show', val);
				}
			}
		},
		
		watch: {
			data: {
				immediate: true,
				handler(val) {
					if(!Object.keys(val).length) return;
//					console.log('val',val)
					if(val.type == 0) {
		  			this.videoUrl = val.url;
					}
					if(val.type == 1) {
						this.picList = val.url;
						if(this.picList.length > 4){
							this.swiperOptionThumbs.slidesPerView = 4;
						}else{
							this.swiperOptionThumbs.slidesPerView = this.picList.length;
						}
						this.$nextTick(() => {
							this.handleSwiper();
							this.$previewRefresh();
						})
					}
					this.title = val.name;
				}
			}
		},

		mounted() {
		},

		methods: {
			handleSwiper() {
				const swiperTop = this.$refs.swiperTop.swiper;
				const swiperThumbs = this.$refs.swiperThumbs.swiper;
				swiperTop.controller.control = swiperThumbs;
				swiperThumbs.controller.control = swiperTop;
			},
			
			checkImage(url, imgName) {
				this.imgName = imgName;
				this.currentCheckImg = url;
				this.innerVisible = true;
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.video {
		border:none;
		background-color:transparent;
		outline:none;
	}
	.video-box,
	.carousel-box {
		height: 400px;
		margin-bottom: 13px;
		border-radius: 6px 6px 0 0;
		background-color: #fff;
	}
	
	.carousel-box {
		.carousel-img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			max-height: 100%;
		}
	}
	
	.gallery-top {
		height: 80% !important;
		width: 100%;
	}
	
	.img-box {
		position: relative;
		height: calc(100% - 20px);
	}
	
	/deep/.gallery-thumbs {
		height: 20% !important;
		box-sizing: border-box;
		padding: 10px 0;
		.swiper-slide {
			width: 25%;
			height: 100%;
			opacity: 0.4;
		}
		.swiper-slide-active {
			opacity: 1;
		}
	}
	
</style>
<style type="text/css">
	.pswp {
		z-index: 999999!important;
	}
</style>