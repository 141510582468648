<template>
  <el-dialog class="z-dialog" title="自动推荐" :visible.sync="isShow" width="400px" :close-on-click-modal="false">
    <el-form label-width="150px">
      <el-form-item :label="item.groupName +' - '+ item.channelName " v-for="(item, index) in list" :key="index">
        <el-input-number
          v-model.number="item.num"
          controls-position="right"
          :min="0"
          :max="99"
          :rules="[{required: true, message: '请输入数量', trigger: 'blur' },{ type: 'number', message: '必须为数字值'}]"
          maxlength="2"
        ></el-input-number>

      </el-form-item>

    </el-form>

    <div slot="footer" class="text-right">
      <el-button @click="isShow = false">取消</el-button>
      <el-button type="primary" @click="handleSubmit" >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      list: []
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {}
  },

  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      }
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
    	
    	this.$post("/queryChennlByActivityId", {
				activityId: this.data.activityId
			}).then(m => {
				if(m.resultCode == 0) {
					this.list = m.content.list;
				}
			})
			
    },

    handleSubmit() {
//  	for(var i=0;i<this.list.length;i++){
//  		console.log(this.list[i]);
//  	}
    	
    	this.$post("/userZidongtuijian", {
				activityId: this.data.activityId,
				json: JSON.stringify(this.list)
			}).then(m => {
				if(m.resultCode == 0) {
					this.isShow = false;
					this.$emit("init");
				}
			})
    	
    }

  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-form > .el-form-item > .el-form-item__content {
  padding: 0 5px;
}
</style>