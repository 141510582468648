<template>
	<!-- 汇总 -->
	<z-plate has-bottom-border>
		<!-- title 左侧 -->
		<template #titleLeft>
			<el-button type="primary" @click="exportAllFujian">导出全部附件</el-button>
			<el-button type="primary" @click="exportFujian">批量导出附件</el-button>
			<el-button type="primary" @click="zidongtuijian">自动推荐</el-button>
		</template>

		<!-- title 右侧按钮 -->
		<template #titleRight>
			<el-button type="primary" @click="exportTuijian">一键导出汇总表</el-button>
		</template>

		<!-- 表格 -->
		<el-table class="z-table" :data="tableData" v-loading="tableLoading" @selection-change="handleSelectionChange"
			style="width: 100%" v-if="tableData.length">
			<el-table-column type="selection" width="60"></el-table-column>

			<el-table-column type="index" label="序号" width="60"></el-table-column>

			<el-table-column prop="channelName" label="作品分类"></el-table-column>

			<el-table-column prop="randomCode" label="作品编码" width="80"></el-table-column>

			<el-table-column label="作品名称">
				<template slot-scope="{ row }">
					<strong class="color-primary cursor"
						@click="checkWorks(row.videoId, row.videoType, row.videoStatus, row.videoUrl)">{{ row.videoName }}</strong>
				</template>
			</el-table-column>

			<el-table-column prop="schoolName" label="上报单位"></el-table-column>

			<el-table-column label="操作">
				<template slot-scope="{ row }">
					<el-button type="text" @click="deleteFlag(row)">移除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!-- 分页 -->
		<!-- <z-pagination
      v-if="pagination.total > 0"
      :page.sync="pagination.page"
      :limit.sync="pagination.limit"
      :total="pagination.total"
      @pagination="init"
    ></z-pagination>-->

		<v-nodata v-else></v-nodata>

		<v-dialog v-if="dialog" :show.sync="dialog" :data="data" @init="init"></v-dialog>

		<v-check-works v-if="dialogVisible" :show.sync="dialogVisible" :data="dialogData"></v-check-works>
	</z-plate>
</template>

<script>
import VDialog from "./RecommendDialog";
import VCheckWorks from './CheckWorks'
export default {
	components: {
		VDialog,
		VCheckWorks
	},

	data() {
		return {
			tableData: [],
			pagination: {
				page: 0, //当前页
				limit: 10, //分页条数
				total: 0 //总页数
			},
			tableLoading: false,
			dialog: false,
			activityId: sessionStorage.getItem("group_activityid"),
			data: {
				activityId: ''
			},
			multipleSelection: [],
			dialogData: {},
			dialogVisible: false
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			this.tableLoading = true;
			this.$post("/queryTuijianList", {
				activityId: this.activityId
			}).then(m => {
				if (m.resultCode == 0) {
					this.tableData = m.content.list;
					this.tableLoading = false;
				}
			})
		},

		deleteFlag(row) {
			//			console.log(row);
			this.$post("/changeActivityRecommend", {
				videoId: row.videoId,
				recommendFlag: 0
			}).then(m => {
				if (m.resultCode == 0) {
					this.$message({
						type: 'success',
						message: '操作成功！'
					});

					this.init();
				}
			})

		},

		zidongtuijian() {

			this.data.activityId = this.activityId;
			this.dialog = true;
		},

		exportTuijian() {
			if (!this.tableData.length) {
				this.$message({
					type: 'info',
					message: '暂无导出数据信息'
				});
				return;
			}
			window.open("api/exportTuijianByActivityId?activityId=" + this.activityId + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
		},

		exportFujian() {

			if (!this.multipleSelection.length) {
				this.$message({
					type: 'info',
					message: '你没有选择需要导出附件的信息'
				});
				return;
			}
			let videos = "";
			for (let i = 0; i < this.multipleSelection.length; i++) {
				videos += this.multipleSelection[i].videoId + ",";
			}

			videos = videos.substring(0, videos.length - 1);

			//			console.log('导出附件',videos);

			//			window.open("/downVideoFiles?videoIds="+videos+"&access_token="+sessionStorage.getItem("ACCESS_TOKEN"));
			this.$post("/downVideoFiles", {
				videoIds: videos
			}).then(m => {
				if (m.resultCode == 0) {
					var files = m.content.uploadfiles.split("@");
					for (var i = 0; i < files.length; i++) {
						window.open(files[i]);
					}
				}
			})

		},

		exportAllFujian() {
			var url = "";
			//			alert("全部到处附件……");
			this.$post("/getAllFilesPath", {
				activityId: this.activityId
			}).then(m => {
				if (m.resultCode == 0) {
					var files = m.content.uploadfiles.split("@");
					for (var i = 0; i < files.length; i++) {
						url += files[i] + " \r\n ";
					}
					this.$message({
						type: 'info',
						message: url
					});
				}
			})
		},

		handleSelectionChange(row) {
			this.multipleSelection = row;
		},

		checkWorks(videoid, type, status, videoUrl) {
			this.dialogData = {}
			this.$nextTick(() => {
				if (type == 0) { //视频类
					if (status != 3) {
						this.$message({
							type: 'info',
							message: '视频正在转码中'
						});
						return;
					} else {
						this.dialogData = {
							type: 0,
							url: videoUrl
						}
						this.dialogVisible = true;
					}
				} else if (type == 1) { //图片类
					this.dialogVisible = true;
					this.loadPicListByVideoId(videoid).then(rep => {
						this.dialogData = {
							type: 1,
							url: rep.content.picList
						}
					});
				}
			})
		},
		loadPicListByVideoId(videoid) {
			return this.$post('/loadPicListByVideoId', {
				"videoid": videoid
			});
		},

	}
};
</script>

<style lang="scss" scoped>
</style>